import { FoundResultSectionDataP72P73 } from './Data/P72/P72.data';
import { FoundResultSectionDataP75 } from './Data/P75/P75.data';
import { FoundResultSectionDataP77 } from './Data/P77/P77.data';
import { FoundResultSectionDataP65andP831 } from './Data/P65/P65.data';
import { FoundResultSectionDataP62 } from './Data/P62/P62.data';
import { FoundResultSectionDataP61 } from './Data/P61/P61.data';
import { FoundResultSectionDataP63 } from './Data/P63/P63.data';
import { FoundResultSectionDataP851 } from './Data/P851/P851.data';
import { FoundResultSectionDataP871 } from './Data/P871/P871.data';
import { FoundResultSectionDataP6012 } from './Data/P6012/P6012.data';
import { FoundResultSectionDataC11C13C14 } from './Data/C11/C11.data';
import { FoundResultSectionDataP891 } from './Data/P891/P891.data';
import { FoundTestTableSectionDataP721 } from './Data/P721/P721.data';
import { FoundResultSectionDataP711 } from './Data/P711/P711.data';
import { FoundResultSectionDataP710 } from './Data/P710/P710.data';
import { FoundResultSectionDataP6142 } from './Data/P6142/P6142.data';

export const selectFoundResultTemplateForForm = (selectedTag, formName, formData) => {
    const formNameToFunctionMap = {
        'P7-2': () => FoundResultSectionDataP72P73(selectedTag, formData),
        'P7-3': () => FoundResultSectionDataP72P73(selectedTag, formData),
        'P7-5': () => FoundResultSectionDataP75(selectedTag, formData),
        'P7-7': () => FoundResultSectionDataP77(selectedTag, formData),
        'P6-5': () => FoundResultSectionDataP65andP831(selectedTag, formData, formName),
        'P8-3.1': () => FoundResultSectionDataP65andP831(selectedTag, formData, formName),
        'P6-5.2': () => FoundResultSectionDataP65andP831(selectedTag, formData, formName),
        'P6-2': () => FoundResultSectionDataP62(selectedTag, formData),
        'P8-2.1': () => FoundResultSectionDataP62(selectedTag, formData),
        'P6-4': () => FoundResultSectionDataP62(selectedTag, formData),
        'P6-2.2': () => FoundResultSectionDataP62(selectedTag, formData),
        'P6-4.2': () => FoundResultSectionDataP62(selectedTag, formData),
        'P6-1': () => FoundResultSectionDataP61(selectedTag, formData),
        'P8-1.1': () => FoundResultSectionDataP61(selectedTag, formData),
        'P6-1.2': () => FoundResultSectionDataP61(selectedTag, formData),
        'P6-3': () => FoundResultSectionDataP63(selectedTag, formData),
        'P7-4': () => FoundResultSectionDataP63(selectedTag, formData),
        'P7-4.1': () => FoundResultSectionDataP63(selectedTag, formData),
        'P6-3.2': () => FoundResultSectionDataP63(selectedTag, formData),
        'P8-5.1': () => FoundResultSectionDataP851(selectedTag, formData),
        'P8-7.1': () => FoundResultSectionDataP871(selectedTag, formData),
        'P6-12': () => FoundResultSectionDataP6012(selectedTag, formName, formData),
        'P8-8.1': () => FoundResultSectionDataP6012(selectedTag, formName, formData),
        'P7-15': () => FoundResultSectionDataP6012(selectedTag, formName, formData),
        'P6-22': () => FoundResultSectionDataP6012(selectedTag, formName, formData),
        'P7-22': () => FoundResultSectionDataP6012(selectedTag, formName, formData),
        'P7-14': () => FoundResultSectionDataP6012(selectedTag, formName, formData),
        'P7-18': () => FoundResultSectionDataP6012(selectedTag, formName, formData),
        'P6-27': () => FoundResultSectionDataP6012(selectedTag, formName, formData),
        'P7-29': () => FoundResultSectionDataP6012(selectedTag, formName, formData),
        'P8-12.1': () => FoundResultSectionDataP6012(selectedTag, formName, formData),
        'P7-30': () => FoundResultSectionDataP6012(selectedTag, formName, formData),
        'C1-1': () => FoundResultSectionDataC11C13C14(selectedTag, formData),
        'C1-3': () => FoundResultSectionDataC11C13C14(selectedTag, formData),
        'C1-4': () => FoundResultSectionDataC11C13C14(selectedTag, formData),
        'C1-3.2': () => FoundResultSectionDataC11C13C14(selectedTag, formData),
        'P8-9.1': () => FoundResultSectionDataP891(selectedTag, formData),
        'P7-19': () => FoundResultSectionDataP891(selectedTag, formData),
        'P7-21': () => FoundTestTableSectionDataP721(selectedTag, formData),
        'P7-11': () => FoundResultSectionDataP711(selectedTag, formData),
        'P8-13.1': () => FoundResultSectionDataP711(selectedTag, formData),
        'P7-10': () => FoundResultSectionDataP710(selectedTag, formData),
        'P6-8': () => FoundResultSectionDataP710(selectedTag, formData),
        'P7-8': () => FoundResultSectionDataP710(selectedTag, formData),
        'P7-12': () => FoundResultSectionDataP710(selectedTag, formData),
        'P6-17': () => FoundResultSectionDataP710(selectedTag, formData),
        'P6-14.2': () => FoundResultSectionDataP6142(selectedTag, formData),
        'P6-14': () => FoundResultSectionDataP6142(selectedTag, formData),
        'P7-31': () => FoundResultSectionDataP6142(selectedTag, formData),
        'P6-23.2': () => FoundResultSectionDataP6142(selectedTag, formData)
    };
    let filterViewData = { rows: [] };
    const functionToExecute = formNameToFunctionMap[formName];
    if (functionToExecute) {
        filterViewData = functionToExecute();
    }
    return filterViewData;
};
