import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { MRATSync } from 'components/MRATSync';
import imageStore from 'utils/ImageDB';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { del } from 'idb-keyval';
import { MRAT_USER_ROLE } from 'journeys/portal/Home/Home.constants';
import { Trim, MatchSRA } from 'helpers/Validation';
import { PrintButton, InformationButton, PaletteButton } from 'elements/CustomHeaderButtons/HeaderButtons';
import { isMobile as isTablet } from 'react-device-detect';
import BackButton from '../../../icons/Arrow - Left';
import { HeaderContainer, HeaderRibbon, GradientLine, HeaderText, TotalRecords, HeaderButton, PrintIconStyle } from './Header.styled';
import UserDropdown from './subSections/UserDropdown/UserDropdown';
import { setCurrentView } from '../../portal/Home/Home.actions';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import ColorLedger from './Ledger';

const SecondaryHeaderButton = ({ buttonText, handleButton, isDPSDisabled }) => {
    if (buttonText) {
        return (
            <HeaderButton type="button" onClick={handleButton} width={250} disabled={isDPSDisabled}>
                {buttonText}
            </HeaderButton>
        );
    }

    return null;
};
const Header = (props) => {
    const { isMRATOnline, selectedAssetName, userRole, headerText, handleButton,
        buttonText, ButtonSection, totalCount, showTotalCount, buttonWidth, buttonHeight, displayUnsavedChangesPopUp,
        headerPrintButtonHandler, closeCameraButtonVisible,
        displayFormStatus, goBack, newImageList, setCurrentAppView, currentAppView, showOfflineFormsCount,
        titleFontSize, formName, selectedTrfId, selectedTag, switchSRAForm, isDPSDisabled, showInfoIcon } = props;
    const [unSavedChangesAlert, handleUnSavedChangesAlert] = useState(false);
    const [colorLedgerVisible, setcolorLedgerVisible] = useState(false);
    const displayPopUp = MRAT_USER_ROLE.Technician === userRole;
    const newTrfId = selectedTrfId ?? Trim(selectedTag.TrfId);
    const classes = makeStyles({
        button: {
            '&:hover': {
                backgroundColor: '#335B03',
                borderColor: 'transparent'
            },
            width: buttonWidth ? buttonWidth.toString().concat('px !important') : '105px',
            height: buttonHeight ? buttonHeight.toString().concat('px !important') : '80px'
        }
    })();
    const headerBackButtonHandler = () => {
        if (MatchSRA(Trim(newTrfId)) && currentAppView === 'P8-1') {
            switchSRAForm();
        } else {
            handleUnSavedChangesAlert(true);
        }
    };
    const headerInfoButtonHandler = () => {
        if (currentAppView === 'admin') {
            setCurrentAppView('infoAdmin');
        } else {
            setCurrentAppView('info');
        }
    };
    const headerInfoBackButton = () => {
        if (currentAppView === 'infoAdmin') {
            setCurrentAppView('admin');
        } else {
            goBack && setCurrentAppView(goBack);
        }
    };
    const handleUnSavedChanges = () => {
        handleUnSavedChangesAlert(false);
        goBack && setCurrentAppView(goBack);
        newImageList.length !== 0 && newImageList.map((item) => del(item, imageStore));
    };

    const handleOnClose = () => {
        setcolorLedgerVisible(false);
    };
    const infoBack = showInfoIcon ? (
        <InformationButton
            buttonHandler={headerInfoButtonHandler}
            tooltip="User Guide"
        />
    ) : (
        <BackButton
            onClick={() => {
                (displayUnsavedChangesPopUp || (displayFormStatus && (displayPopUp || (MatchSRA(newTrfId) && currentAppView === 'P8-1'))))
                    ? headerBackButtonHandler()
                    : headerInfoBackButton();
            }}
        />
    );
    return (
        <div>
            <HeaderContainer titleFontSize={titleFontSize} formName={formName} isDPSDisabled={isDPSDisabled}>
                <img className="cropped" src="/images/bp logo.png" alt="BPLogo" />
                {
                    currentAppView === 'home' ? (
                        <TotalRecords style={{ width: '8%', visibility: 'hidden' }}>
                            Total Records
                            {' '}
                            {totalCount}
                        </TotalRecords>
                    ) : ''
                }
                {
                    showTotalCount && (
                        <TotalRecords style={{ fontSize: '11pt' }}>
                            Total Records
                            {' '}
                            {totalCount}
                        </TotalRecords>
                    )
                }
                {
                    showOfflineFormsCount && (
                        <TotalRecords style={{ fontSize: '11pt' }} width="9%">
                            Total Records Saved Offline
                            {' '}
                            {totalCount}
                        </TotalRecords>
                    )
                }
                <HeaderText id="headerText" titleFontSize={titleFontSize}>
                    {headerText}
                </HeaderText>
                <div className="buttonCollection" id="buttonCollection">
                    {isMRATOnline && <MRATSync id="MRATSyncButton" />}
                    {(currentAppView === 'addDpsForm' || currentAppView === 'editDpsForm') && (
                        <div style={{ marginRight: '10px' }}>
                        <PaletteButton buttonHandler={() => setcolorLedgerVisible(true)} tooltip="Color Ledger" />
                        </div>
                    )}
                    {colorLedgerVisible && <ColorLedger handleOnClose={handleOnClose} />}
                    {ButtonSection}
                    <SecondaryHeaderButton
                        handleButton={handleButton}
                        buttonText={buttonText}
                        classes={classes}
                        isDPSDisabled={isDPSDisabled}
                    />
                    {
                        closeCameraButtonVisible || currentAppView === 'userRoleSelector' || currentAppView === 'error' ? '' : infoBack
                    }
                    {closeCameraButtonVisible || !isMRATOnline ? '' : <UserDropdown displayFormStatus={displayFormStatus} />}
                </div>
            </HeaderContainer>
            {currentAppView === 'home' && isMRATOnline && !isTablet && (MRAT_USER_ROLE.View !== userRole) && (
                <HeaderRibbon>
                    {(selectedAssetName !== '') && (
                        <div style={{ direction: 'rtl', marginRight: '4.55rem' }}>
                            <PrintIconStyle>
                                <PrintButton
                                    buttonHandler={headerPrintButtonHandler}
                                    tooltip="Print TRF"
                                />
                            </PrintIconStyle>
                        </div>
                    )}
                </HeaderRibbon>
            )}
            <GradientLine />
            {unSavedChangesAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={unSavedChangesAlert}
                    handleClose={() => handleUnSavedChangesAlert(false)}
                    handleAlertFunction={() => handleUnSavedChanges()}
                />
            )}
        </div>
    );
};

const mapStateToProps = ({ AppData, Forms, Home }) => ({
    isMRATOnline: AppData.isMRATOnline,
    newImageList: Forms.newImageList,
    selectedTag: Forms.selectedTag,
    selectedTrfId: Forms.selectedTrfId,
    selectedAssetName: Home.selectedAssetName,
    userRole: Home.userRole,
    currentAppView: Home.currentView
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    setCurrentAppView: (view) => dispatch(setCurrentView(view))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

SecondaryHeaderButton.propTypes = {
    buttonText: PropTypes.string,
    handleButton: PropTypes.func,
    isDPSDisabled: PropTypes.bool
};

Header.propTypes = {
    buttonHeight: PropTypes.number,
    ButtonSection: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.object
    ]),
    buttonText: PropTypes.string,
    buttonWidth: PropTypes.number,
    closeCameraButtonVisible: PropTypes.bool,
    currentAppView: PropTypes.string,
    displayFormStatus: PropTypes.bool,
    displayUnsavedChangesPopUp: PropTypes.bool,
    formName: PropTypes.string,
    goBack: PropTypes.string,
    handleButton: PropTypes.func,
    headerPrintButtonHandler: PropTypes.func,
    headerText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    isDPSDisabled: PropTypes.bool,
    isMRATOnline: PropTypes.bool.isRequired,
    newImageList: PropTypes.array,
    selectedAssetName: PropTypes.string,
    selectedTag: PropTypes.object,
    selectedTrfId: PropTypes.string,
    setCurrentAppView: PropTypes.func,
    showInfoIcon: PropTypes.bool,
    showOfflineFormsCount: PropTypes.bool,
    showTotalCount: PropTypes.bool,
    switchSRAForm: PropTypes.func,
    titleFontSize: PropTypes.string,
    totalCount: PropTypes.number,
    userRole: PropTypes.string
};
